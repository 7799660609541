@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
  height: 100vh; /* Set the app height to full viewport height */
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  flex-grow: 1; /* Allow header to grow and fill the available space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Changed from flex-start to space-between */
  color: white;
  padding: 20px;
  overflow-y: auto; /* Add scroll to header if content overflows */
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 100px); /* Adjust based on padding and dedication height */
  overflow-y: auto; /* Allow scrolling if content is too tall */
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
}

.date-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.date {
  font-size: 1.2rem;
  margin: 6px 0;
  font-weight: 300;
}

.previous-date {
  font-size: 0.9rem;
  color: #bbb;
}

.next-date {
  font-size: 1.5rem;
  font-weight: 400;
  color: #61dafb;
}

.days-until {
  margin-top: 20px;
  font-size: 1.1rem;
  font-weight: 400;
}

.dedication {
  font-size: 0.9rem;
  font-weight: 300;
  color: #bbb;
  padding: 10px 0;
}

@media (max-width: 600px) {
  .App-header {
    padding: 15px;
  }

  h1 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .date {
    font-size: 1rem;
    margin: 4px 0;
  }

  .next-date {
    font-size: 1.2rem;
  }

  .previous-date {
    font-size: 0.8rem;
  }

  .days-until {
    font-size: 0.9rem;
  }

  .dedication {
    font-size: 0.8rem;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}